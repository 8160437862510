import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import styled from "styled-components"

import { Section, Container } from "../global"
import Localizacao from "../images/maps"

const Features = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            phone
            email
          }
        }
      }
    `
  )

  const phone = `https://api.whatsapp.com/send?phone=+${site.siteMetadata.phone}&text=Olá, tudo bem?`
  const email = `mailto:${site.siteMetadata.email}`

  return (
    <Section id="top">
      <StyledContainer>
        <h1>Entre em Contato</h1>
        <p>
          A <u>Ouro Estamparia Digital</u> é uma empresa da{" "}
          <strong>Ouroboros</strong> e está registrada sob o{" "}
          <strong>CNPJ: 26.836.959-0001/55</strong>.
        </p>
        <p>
          Nosso endereço:{" "}
          <strong>
            Rua 412A, número 593, sala 08 (esquina com a Rua 434). CEP 88220-000, Morretes, Itapema - SC.
          </strong>
        </p>
        <br />
        <Localizacao></Localizacao>
        <br />
        <p>Contato:</p>
        <ul>
          <li>
            <a href={phone} target="_blank" rel="noopener noreferrer">
              Nos chame no whatsapp
            </a>
            ;
          </li>
          <li>
            <a href={email} target="_blank" rel="noopener noreferrer">
              Envie um email
            </a>
            .
          </li>
        </ul>
      </StyledContainer>
    </Section>
  )
}

export default Features

const StyledContainer = styled(Container)`
  width: 60%;
  max-width: 670px;
  margin: 0px auto;
  padding-top: 10vh;

  text-align: justify;

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    padding-top: 10vh;
  }

  p {
    line-height: 24px;
  }

  a {
    color: ${props => props.theme.color.accent};
  }

  ul {
    list-style: circle;
  }
`
