import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// const Image = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       staticMap {
//         childFile {
//           childImageSharp {
//             fluid(maxWidth: 1000) {
//               ...GatsbyImageSharpFluid_tracedSVG
//             }
//           }
//         }
//       }
//     }
//   `)

//   console.log(data)

//   return <Img fluid={data.staticMap.childFile.childImageSharp.fluid} />
// }

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      file(
        sourceInstanceName: { eq: "geral" }
        name: { eq: "google-maps-static" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  console.log(data)

  return <Img fluid={data.file.childImageSharp.fluid} />
}

export default Image
