import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import WhatsButton from "../components/common/whatsfloating"

import Body from "../components/sections/contato"
import Footer from "../components/sections/footer"

const CamisetasPage = () => {
	return (
		<Layout>
		<WhatsButton />
			<SEO
				title=" Contato [Ouro Estamparia Digital]"
				description="Como entrar em contato conosco? Envie um email para contato@ouroestamparia ou um whats para (47)988682772"
				pathname="/contato"
			/>
			<Navigation></Navigation>
			<Body></Body>
			<Footer />
		</Layout>
	)
}

export default CamisetasPage
